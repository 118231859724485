<template>
  <div>
    <el-card :body-style="{ padding: '25px' }">
      <div slot="header">
        <div style="margin-left: 0px; display: flex">
          <div class="select2">
            <el-select
              v-model="selectTipoBusqueda"
              slot="prepend"
              style="width:140px"
            >
              <el-option label="Nombre" :value="1"></el-option>
              <el-option label="Teléfono" :value="2"></el-option>
            </el-select>
          </div>
          <div class="buscador2">
            <!-- Nombre -->
            <maca-input-delay
              v-show="selectTipoBusqueda == 1"
              v-model="nombreBusqueda"
              icon="el-icon-search"
              placeholder="Buscar por nombre"
            ></maca-input-delay>
            <!-- Teléfono -->
            <maca-input-delay
              v-show="selectTipoBusqueda == 2"
              v-model="telefonoBusqueda"
              icon="el-icon-search"
              placeholder="Buscar por teléfono"
            ></maca-input-delay>
          </div>
        </div>
        <el-button
          class="botonmasIcono"
          icon="el-icon-plus"
          type="primary"
          round
          style="margin-right: 0px"
          @click="$refs.modalNuevo.abrir()"
          >Nuevo</el-button
        >
      </div>

      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column label="Nombre" prop="nombre" sortable>
          </el-table-column>

          <el-table-column
            label="Teléfono"
            prop="telefono"
            sortable
          ></el-table-column>

          <el-table-column
            label="Domicilio"
            prop="domicilio"
            sortable
          ></el-table-column>
          <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                type="primary"
                circle
                @click="$refs.modalModificar.abrir(props.row.id)"
                :disabled="!$store.getters.tienePermiso('M_USE')"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                @click="eliminar(props.row.id)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "usuario",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: "/clienteReserva/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      nombreBusqueda: "",
      telefonoBusqueda: "",
      selectTipoBusqueda: 1,
      filtroNombre: null,
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el cliente."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/clienteReserva/eliminar",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Cliente borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleBloqueado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id };
      let respuestaApi = null;

      if (fila.bloqueado) {
        respuestaApi = await this.$maca.api.post("/user/bloquear", params);
      } else {
        respuestaApi = await this.$maca.api.post("/user/desbloquear", params);
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    nombreBusqueda() {
      if (this.selectTipoBusqueda == 1) {
        this.paramsTabla = { nombre: this.nombreBusqueda };
      } else {
        this.paramsTabla = {};
      }
      this.actualizarTabla = true;
    },
    telefonoBusqueda() {
      if (this.selectTipoBusqueda == 2) {
        this.paramsTabla = { telefono: this.telefonoBusqueda };
      } else {
        this.paramsTabla = {};
      }
      this.actualizarTabla = true;
    },
  },
};
</script>
