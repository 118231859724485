<template>
  <div>
    <maca-modal titulo="Nuevo Cliente" :impedirClose="impedirClose" ref="modal">
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        label-position="left"
        label-width="130px"
      >
        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="Teléfono" prop="telefono">
          <el-input v-model="form.telefono"></el-input>
        </el-form-item>
        <el-form-item label="Domicilio" prop="domicilio">
          <el-input v-model="form.domicilio"></el-input>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    return {
      form: {
        nombre: null,
        telefono: null,
        domicilio: null,
      },
      impedirClose: false,
      persona: null,

      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        telefono: [
          {
            required: true,
            message: "Por favor introduzca el telefono.",
            trigger: "change",
          },
        ],
        domicilio: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.persona = null;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        nombre: this.form.nombre,
        telefono: parseInt(this.form.telefono),
        domicilio: this.form.domicilio,
      };

      let respuestaApi = await this.$maca.api.post(
        "/clienteReserva/crear",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Cliente creado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  /* watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  }, */
};
</script>
